import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/creature": [~3],
		"/curios": [~4],
		"/for-hire": [~5],
		"/for-hire/[event]": [~6],
		"/info": [~7],
		"/projects": [~8],
		"/projects/[project_id]": [~9],
		"/showreels": [~10],
		"/showreels/[showreel_id]": [~11],
		"/team": [~12],
		"/work": [~13],
		"/work/[case_study]": [~14]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';